
import {
  defineComponent,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs
} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { highlightKeyword } from '@/utils/index'
import { apiSearch } from '@/api/search'

export default defineComponent({
  name: '',
  props: {},
  setup () {
    const router = useRouter()
    const route = useRoute()
    const state: any = reactive({
      keyword: route.query.keyword,
      searchLoading: true,
      resultList: [],
      total: 0,
      pageNo: 1,
      pageSize: 15,
      relate: ''
    })
    const listLoading = ref(false)
    const finished = ref(false)
    // const refreshing = ref(false)

    const onLoad = () => {
      state.pageNo += 1
      setTimeout(() => {
        search()
      }, 500)
    }
    // const onRefresh = () => {
    //   finished.value = false
    //   state.pageNo = 1
    //   listLoading.value = true
    //   search()
    // }
    const params = ref({})
    const searchEnter = () => {
      if (!state.keyword) {
        // Toast('请输入关键词搜索')
        return
      }
      state.pageNo = 1
      state.resultList = []
      finished.value = false
      router.replace({
        name: 'search',
        query: {
          keyword: state.keyword
        }
      })
      search()
    }
    const search = () => {
      state.relate = state.keyword
      params.value = {
        keyword: state.keyword,
        pageNo: state.pageNo,
        pageSize: state.pageSize
      }
      apiSearch(params.value).then((res: any) => {
        const { data, loading } = res
        if (!loading) {
          state.searchLoading = false
          // state.resultList = data.helpCenterListByKeyword.list
          state.resultList = [
            ...state.resultList,
            ...data.helpCenterListByKeyword.list
          ]
          console.log(state.resultList, 'res', state.total)
          state.resultList = state.resultList.map((item: any) => {
            return {
              ...item,
              newAnswer: item.answer.replaceAll('img', 'div')
            }
          })
          state.total = data.helpCenterListByKeyword.totalRows
          if (state.resultList.length >= state.total && state.total !== 0) {
            finished.value = true
            return
          }
          nextTick(() => transform())
        }
      })
    }

    // 高亮
    const transform = () => {
      console.log('高亮')
      if (state.resultList.length === 0) return
      for (var i = 0; i < state.resultList.length; i++) {
        highlightKeyword(
          document.getElementsByClassName('answer')[i].children[0],
          state.keyword
        )
        highlightKeyword(
          document.getElementsByClassName('question')[i],
          state.keyword
        )
      }
    }
    onMounted(() => {
      state.pageNo = 1
      // state.resultList = []
      // finished.value = false
      search()
    })
    const toQuestion = (sub: any) => {
      router.push({
        path: `/cn/help/${sub.parentId}/subCategory/${sub.questionId}`
      })
      window.scrollTo(0, 0)
    }
    return {
      ...toRefs(state),
      search,
      searchEnter,
      onLoad,
      listLoading,
      finished,
      toQuestion
      //   onRefresh,
      //   refreshing
    }
  }
})
