import apolloClient from '@/apollo-client/index'
import * as gql from '@/graphql/query'
import { ApolloQueryResult } from '@apollo/client'

export const apiSearch = async <T>(
  params: T
): Promise<ApolloQueryResult<any>> => {
  return await apolloClient.query({
    query: gql.helpCenterListByKeyword,
    variables: {
      filter: params
    },
    notifyOnNetworkStatusChange: true
  })
}
