/**
 * ② 高亮关键字
 * @param node - 节点
 * @param pattern - 用于匹配的正则表达式，就是把上面的pattern传进来
 */

export const highlightKeyword = (node: any, keyword: string) => {
  // string为原本要进行匹配的关键词
  // 结果transformString为进行处理后的要用来进行匹配的关键词
  // var transformString = keyword.replace(/[.[*?+^$|()/]|\]|\\/g, '\\$&');
  const pattern: any = new RegExp(keyword, 'i')
  // nodeType等于3表示是文本节点
  if (node?.nodeType === 3) {
    // node.data为文本节点的文本内容
    const matchResult = node.data.match(pattern)
    // 有匹配上的话
    if (matchResult) {
      // 创建一个span节点，用来包裹住匹配到的关键词内容
      const highlightEl = document.createElement('span')
      // 不用类名来控制高亮，用自定义属性data-*来标识，
      // 比用类名更减少概率与原本内容重名，避免样式覆盖
      highlightEl.dataset.highlight = 'yes'
      highlightEl.style.color = '#4187ff'
      // splitText相关知识下面再说，可以先去理解了再回来这里看
      // 从匹配到的初始位置开始截断到原本节点末尾，产生新的文本节点
      const matchNode = node.splitText(matchResult.index)
      // 从新的文本节点中再次截断，按照匹配到的关键词的长度开始截断，
      // 此时0-length之间的文本作为matchNode的文本内容
      matchNode.splitText(matchResult[0].length)
      // 对matchNode这个文本节点的内容（即匹配到的关键词内容）创建出一个新的文本节点出来
      const highlightTextNode = document.createTextNode(matchNode.data)
      // 插入到创建的span节点中
      highlightEl.appendChild(highlightTextNode)
      // 把原本matchNode这个节点替换成用于标记高亮的span节点
      matchNode.parentNode.replaceChild(highlightEl, matchNode)
    }
  }
  // 如果是元素节点 且 不是script、style元素 且 不是已经标记过高亮的元素
  // 不是已经标记过高亮的元素作为条件之一的理由是，避免进入死循环，一直往里套span标签
  if (
    node?.nodeType === 1 &&
    !/script|style/.test(node.tagName.toLowerCase()) &&
    node.dataset.highlight !== 'yes'
  ) {
    // 遍历该节点的所有子孙节点，找出文本节点进行高亮标记
    const childNodes = node.childNodes
    for (let i = 0; i < childNodes.length; i++) {
      highlightKeyword(childNodes[i], pattern)
    }
  }
}

export const group = (array: any, subGroupLength: number): any => {
  let index = 0
  const newArray = []
  while (index < array.length) {
    newArray.push(array.slice(index, (index += subGroupLength)))
  }
  return newArray
}
